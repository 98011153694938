import React, { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import { PieChart } from "./charts/pie";
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  Typography,
  Paper,
} from "@material-ui/core";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import { Tabular } from "./charts/table";
import { SingleBarChart } from "./charts/single-bar";
import { TrendAreaChart } from "./charts/area";
import { TreemapChart } from "./charts/treemap";
import { Leaderboard } from "./charts/leaderboard";
import { Conditionally } from "../components/conditionally";
import { Print, UnfoldMore } from "@material-ui/icons";
import { CategoryBarChart } from "./charts/bar";
import { MapChart } from "./charts/map";
import { HorizontalBarChart } from "./charts/horizontal-bar";
import { useHistory } from "react-router-dom";
import { isNil, mergeAll } from "ramda";
import { useRequestState } from "../hooks/request";
import { formatNumber } from "../utils/currency";
import { compactNumeric, percent } from "../utils/number";
import { Button, useDataProvider } from "react-admin";
import { GlobalFilter, GlobalFilterValues } from "../components/custom-filter";
import moment from "moment";
import { NumberChart } from "./charts/numbers";
import { Helmet } from "react-helmet";
import { useSettings } from "../settings";

const chartTypeMapper = {
  pie: PieChart,
  tabular: Tabular,
  leaderboard: Leaderboard,
  treemap: TreemapChart,
  "single-bar-chart": SingleBarChart,
  "trend-area-chart": TrendAreaChart,
  "bar-chart": CategoryBarChart,
  "map-chart": MapChart,
  "horizontal-bar-chart": HorizontalBarChart,
  number: NumberChart,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop={2}>{children}</Box>}
    </div>
  );
}

export const MultiDashboard = ({ user, ...props }) => {
  const provider = useDataProvider();
  const dashboards = useRequestState(
    () =>
      provider
        .getList("reports/dashboards", {})
        .then((response) => response?.data),
    []
  );

  const [value, setValue] = React.useState();
  const [locationFilter, setLocationFilter] = React.useState({
    resource: "facilities",
    id: null,
  });
  const [periodFilter, setPeriodFilter] = React.useState();
  const [stepFilter, setStepFilter] = React.useState();
  const history = useHistory();
  const ref = useRef();

  useEffect(() => {
    if (!isNil(value)) {
      history.push("?dashboard=" + value);
    }
  }, [value]);

  useEffect(() => {
    if (dashboards.value?.length > 0) {
      const state = mergeAll(
        history.location.search
          .replace("?", "")
          .split(";")
          .map((v) => v.split("="))
          .map(([k, v]) => ({ [k]: v }))
      );

      setValue(parseInt(state.dashboard) || 0);
    }
  }, [dashboards.value]);

  useEffect(() => {
    dashboards.fetch();
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  if (dashboards.loading || !dashboards.value?.length) {
    return (
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        height={400}
      >
        <CircularProgress size={64} />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
        flexDirection={"row"}
      >
        <Helmet>
          <title>
            {dashboards?.value?.[value]?.title} Dashboard | MedX Surveillance
          </title>
        </Helmet>
        <Box flex="1 1 auto">
          <FormControl size="small" variant={"outlined"} fullWidth>
            <Select
              value={value}
              IconComponent={UnfoldMore}
              onChange={(e) => handleChange(e.target.value)}
            >
              {dashboards.value.map((dashboard, i) => (
                <MenuItem key={i} value={i}>
                  {dashboard.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box flex="4 0 auto" />

        <Box>
          <GlobalFilterValues
            location={locationFilter}
            period={periodFilter}
            step={stepFilter}
            onChangeLocation={setLocationFilter}
            onChangePeriod={setPeriodFilter}
            onChangeStep={setStepFilter}
          />
        </Box>

        <Box marginLeft={1} />

        <Box display="flex" flexDirection={"row"}>
          <GlobalFilter
            user={user}
            onChangeLocation={setLocationFilter}
            onChangePeriod={setPeriodFilter}
            onChangeStep={setStepFilter}
          />

          <Box marginLeft={1} />

          <ReactToPrint content={() => ref.current}>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <Button variant="outlined" onClick={handlePrint}>
                  <Print />
                </Button>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </Box>
      </Box>

      <div ref={ref}>
        <Conditionally
          when={!dashboards.loading && dashboards.value.length > 0}
          render={() =>
            dashboards.value.map((dashboard, i) => (
              <TabPanel key={i} value={value} index={i}>
                <Conditionally
                  when={value === i}
                  render={() => (
                    <Dashboard
                      key={i}
                      dashboard={dashboard}
                      user={user}
                      location={locationFilter}
                      period={periodFilter}
                      step={stepFilter}
                      {...props}
                    />
                  )}
                />
              </TabPanel>
            ))
          }
        />
      </div>
    </Box>
  );
};

export const Dashboard = ({
  dashboard,
  user,
  location,
  period,
  step,
  ...props
}) => {
  return (
    <Grid container spacing={3}>
      <PrintHeader dashboard={dashboard} />

      {dashboard.charts
        ?.map(addFormatters)
        ?.map(({ type, grid, visibility, ...chart }, i) => {
          const Chart = chartTypeMapper[type];

          if (Chart) {
            return (
              <Grid item {...grid} key={i}>
                <Chart
                  type={type}
                  location={location}
                  globalPeriod={period}
                  globalStep={step}
                  {...chart}
                  {...props}
                />
              </Grid>
            );
          }

          return <div />;
        })}

      <PrintFooter />
    </Grid>
  );
};

const PrintHeader = ({ dashboard }) => {
  const settings = useSettings();
  return (
    <Grid item xs={12} component={Box} display="none" displayPrint="block">
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent={"space-between"}
        alignItems="center"
        component={Paper}
        padding={1}
      >
        <Box>
          <Typography variant="h6">{dashboard.title} Report</Typography>
          <Typography variant="body">{dashboard.description}</Typography>
          <Typography variant="caption">{settings["instance-name"]}</Typography>
        </Box>

        <Box>
          <img
            style={{ display: "block", margin: "0 auto" }}
            src={settings["instance-icon"]}
            alt="Logo"
            height="36"
          />
        </Box>
      </Box>
    </Grid>
  );
};

const PrintFooter = () => {
  return (
    <Grid item xs={12} component={Box} display="none" displayPrint="block">
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent={"space-between"}
        alignItems="center"
        component={Paper}
        padding={1}
      >
        <Box>
          <Typography variant="overline">
            Powered by{" "}
            <a href="https://medx.international">MedX Surveillance</a>
          </Typography>
        </Box>

        <Box>
          <Typography variant="caption">
            Generated at {moment().format("Do MMMM YYYY")}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

function addFormatters(chart) {
  const mapper = {
    number: formatNumber,
    percent: percent,
    "compact-number": compactNumeric,
  };
  return {
    ...chart,
    tickFormat: mapper[chart.tickFormat],
    format: mapper[chart.format],
  };
}
