import React from "react";
import {
  List,
  ReferenceArrayInput,
  SelectArrayInput,
  Filter,
  TextInput,
  Pagination,
  CreateButton,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  SelectInput,
} from "react-admin";
import { Box, CardActions, Chip, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { isNil } from "ramda";
import { stringify } from "qs";

import { sources } from "../const";
import { superAdmin, admin, dho } from "../roles";
import { Diagnosis } from "../components/diagnosis";
import { DateRangeInput } from "../components/date-range";
import { Conditionally } from "../components/conditionally";
import { Description, SmsFailed, DoneAll, Share } from "@material-ui/icons";
import { StatusField } from "../components/status";
import { diagnosisOptions, statuses } from "./constants";
import { backendUrl } from "../config";
import { MobileListGrid } from "../components/mobile-list-grid";
import {
  filterToLocationQuery,
  locationInputText,
  LocationOptionRenderer,
  matchLocationSuggestion,
} from "../districts/list";
import { SortButton } from "../components/sort";
import { FlatActionButton } from "../components/action-button";

const ResultFilter = ({ permissions, ...props }) => {
  return (
    <Filter {...props}>
      {[
        <SelectArrayInput
          source="status"
          variant="outlined"
          label="Status"
          choices={statuses}
        />,

        <TextInput
          variant="outlined"
          label="Search"
          source="search.patient.primaryId"
        />,

        <TextInput
          variant="outlined"
          label="Sample Id"
          source="search.instrument.sampleId"
        />,

        <TextInput
          variant="outlined"
          label="Cartridge Serial"
          source="search.instrument.cartridgeId"
        />,

        <TextInput
          variant="outlined"
          label="Lot Number"
          source="instrument.reagentLotId"
        />,

        <TextInput
          variant="outlined"
          label="Instrument"
          source="search.instrument.instrumentId"
        />,

        [admin, superAdmin].includes(permissions?.role) ? (
          <ReferenceArrayInput
            label="Facility"
            style={{ minWidth: "95%" }}
            source="facilityId"
            reference="facilities"
            variant="outlined"
            filterToQuery={(name) => ({ search: { name } })}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        ) : null,

        [superAdmin, admin, dho].includes(permissions?.role) ? (
          <ReferenceInput
            source="districtId"
            reference="districts"
            variant="outlined"
            label="Location"
            filterToQuery={filterToLocationQuery}
          >
            <AutocompleteInput
              option
              optionText={<LocationOptionRenderer />}
              matchSuggestion={matchLocationSuggestion}
              inputText={locationInputText}
            />
          </ReferenceInput>
        ) : null,

        <ReferenceArrayInput
          label="Machine"
          style={{ minWidth: "90%" }}
          source="machineId"
          reference="machines"
          variant="outlined"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>,

        <DateRangeInput
          variant="outlined"
          source="dates.completedAt"
          label="Completed"
        />,

        <DateRangeInput
          variant="outlined"
          source="dates.uploadedAt"
          label="Uploaded"
        />,

        <SelectArrayInput
          variant="outlined"
          label="Source"
          source="source"
          choices={sources}
        />,

        <ReferenceArrayInput
          reference="results/cartridges"
          source="instrument.cartridgeType"
          label="Assay"
          variant="outlined"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>,

        <SelectInput
          variant="outlined"
          source="element.diagnosis"
          label="Diagnosis"
          optionValue="value"
          format={(c) => c?.name}
          choices={diagnosisOptions}
        />,

        <SelectInput
          variant="outlined"
          source="status"
          label="Status"
          choices={[
            { id: "completed", name: "Completed" },
            { id: "in-progress", name: "In Progress" },
          ]}
        />,
      ].filter((d) => d)}
    </Filter>
  );
};

export const ListResults = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: "completedAt", order: "DESC" }}
      filters={<ResultFilter permissions={permissions} {...props} />}
      actions={<Actions user={permissions} />}
      pagination={
        <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />
      }
      empty={false}
    >
      <ResultsGrid userPermissions={permissions?.permissions?.user} />
    </List>
  );
};

export const ResultsGrid = (props) => {
  return (
    <MobileListGrid
      {...props}
      metadata={(record) => (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <StatusField
              size="small"
              record={record}
              source="status"
              label="Status"
            />
          </Grid>
          <Conditionally
            when={props.userPermissions?.includes("ppi")}
            render={() => (
              <Grid item>
                <Chip
                  icon={
                    isNil(record.request) ? null : (
                      <Description color="action" fontSize="small" />
                    )
                  }
                  label={record.instrument.sampleId?.toUpperCase()}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            )}
          />
          <Grid item>
            <Chip label={record?.lab?.name} variant="outlined" size="small" />
          </Grid>
          <Grid item>
            <Chip
              label={record?.instrument?.moduleSite}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      )}
      content={(record) => (
        <Grid container spacing={1} alignItems="flex-end">
          {record.diagnosis.map((diagnosis, i) => (
            <Grid item key={i}>
              <Diagnosis
                name={diagnosis.name}
                quantitative={diagnosis.value?.quantitative}
                qualitative={diagnosis.value?.qualitative}
                unit={diagnosis?.unit}
                range={diagnosis?.range}
              />
            </Grid>
          ))}
          <Grid item>
            <Chip label={`by ${record?.operator}`} size="small" />
          </Grid>
        </Grid>
      )}
      right={(record) => (
        <Box display="flex" flexDirection="column" alignItems={"flex-end"}>
          <Conditionally
            when={!isNil(record.notification)}
            render={() =>
              record.notification.status === "success" ? (
                <DoneAll htmlColor="green" />
              ) : (
                <SmsFailed color="error" />
              )
            }
          />
          <Typography variant="caption">
            {moment(record.completedAt).fromNow()}
          </Typography>
        </Box>
      )}
      link={(record) => `/results/${record.id}/show`}
    />
  );
};

const Actions = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  user,
}) => {
  return (
    <>
      <CardActions>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}

        <Conditionally
          when={user?.permissions?.results?.includes("entry")}
          render={() => <CreateButton basePath={basePath} />}
        />

        <FlatActionButton
          icon={<Share />}
          title={"Export"}
          handler={() => exportResults({ filter: filterValues })}
          success={"Done!"}
          variant="contained"
          color={"primary"}
        />

        <SortButton
          fields={[
            { id: "uploadedAt", name: "Upload Time" },
            { id: "completedAt", name: "Complete Time" },
          ]}
        />
      </CardActions>
    </>
  );
};

function exportResults(filter) {
  const url = `/portal/results/export?${stringify({
    ...filter,
    access_token: localStorage.getItem("token"),
  })}`;
  window.open(`${backendUrl}${url}`);

  return Promise.resolve();
}
