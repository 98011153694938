import React from "react";
import {
  IconButton,
  CircularProgress,
  Tooltip,
  Button
} from "@material-ui/core";
import { refreshView, showNotification, Button as RaButton } from "react-admin";
import { connect } from "react-redux";
import { useRequestState } from "../hooks/request";

const ActionButtonPresenter = ({
  title,
  handler,
  success,
  disabled,
  refreshView: refresh,
  showNotification: notify,
  component = "icon-button",
  children
}) => {
  const action = useRequestState(handler);

  function handleAction() {
    action
      .fetch()
      .then(data => {
        refresh();
        if (typeof success === "function") return notify(success(data));

        return success && notify(success);
      })
      .catch(error => {
        notify(error?.response?.data?.message || error.message);
      });
  }

  if (component === "button") {
    return (
      <Tooltip title={title}>
        <Button variant="outlined" onClick={handleAction} disabled={disabled}>
          {action.loading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            children
          )}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={title}>
      <IconButton
        color="primary"
        onClick={handleAction}
        style={{
          padding: "0 12px 0 12px",
          display: "flex",
          alignItems: "center"
        }}
      >
        {action.loading ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          children
        )}
      </IconButton>
    </Tooltip>
  );
};

const FlatActionButtonPresenter = ({
  title,
  handler,
  success,
  refreshView: refresh,
  showNotification: notify,
  icon,
  color,
  variant = "contained"
}) => {
  const action = useRequestState(handler);

  function handleAction() {
    action
      .fetch()
      .then(data => {
        refresh();
        if (typeof success === "function") return notify(success(data));

        return success && notify(success);
      })
      .catch(error => {
        notify(error?.response?.data?.message || error.message);
      });
  }

  return (
    <Tooltip title={title}>
      <RaButton
        variant={variant}
        label={title}
        color={color}
        onClick={handleAction}
      >
        {action.loading ? <CircularProgress size={24} color="primary" /> : icon}
      </RaButton>
    </Tooltip>
  );
};

export const ActionButton = connect(null, {
  refreshView,
  showNotification
})(ActionButtonPresenter);

export const FlatActionButton = connect(null, {
  refreshView,
  showNotification
})(FlatActionButtonPresenter);
